.tabs {
  &__header {
    border-bottom: 1px solid $red;
    color: $red;
    font-size: 1.25rem;
    margin-bottom: 15px;
    text-transform: uppercase;

    @media #{$smartphone} {
      display: table;
      width: 100%;
    }

    label {
      color: darken($grey, 50%);
      cursor: pointer;
      display: inline-block;
      margin-right: 30px;
      padding-bottom: 15px;
      position: relative;

      &.active {
        color: $red;

        &::after {
          border-top: 3px solid $red;
          content: '';
          left: 0;
          position: absolute;
          top: calc(100% - 2px);
          width: 100%;
        }
      }

      .plus {
        display: none;
      }

      @media #{$smartphone} {
        display: table-cell;
        font-size: 1rem;
        margin-right: 0;
        text-align: center;

        b {
          display: none;
        }

        .plus {
          display: inline-block;
        }
      }
    }
  }

  &__content {
    display: none;

    &.active {
      display: block;
    }
  }
}
